<template>
  <div>
    <search-issue-manages
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="isShowNewModal = true" v-if="isShowMenu('customer_service_manages/issue/new')">新增</a-button>

        <destroy-issue
          v-if="isShowMenu('customer_service_manages/issue/delete')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />

        <!-- <a-button type="primary" @click="exportData">导出</a-button> -->
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <span
        slot="actions"
        v-if="isShowMenu('customer_service_manages/issue/edit')"
        slot-scope="text, record"
      >
        <a-space>
          <a @click="showEditModal(record.id)">编辑</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增记录模态框 -->
    <new-issue
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑记录模态框 -->
    <edit-issue
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import SearchIssueManages from '@/views/customer_service_manages/SearchIssue'
import Pagination from '@/components/Pagination'
import { hasPermission } from '@/utils/permission'
import { findCustomerServiceIssues } from '@/api/customer_service_manage'
// import { exportExcel } from '@/api/excel'
export default {
  name: 'IssueManages',
  components: {
    SearchIssueManages,
    NewIssue: () => import('@/views/customer_service_manages/New'),
    EditIssue: () => import('@/views/customer_service_manages/Edit'),
    DestroyIssue: () => import('@/views/customer_service_manages/Destroy'),
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false,
      isShowEditModal: false,
      selectedRowKeys: [],
      editingId: 0, // 正在编辑的记录id
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '标题',
          dataIndex: 'title'
        },
        {
          title: '客户',
          dataIndex: 'agent_name'
        },
        {
          title: '类型',
          dataIndex: 'type'
        },
        {
          title: '日期',
          dataIndex: 'happen_date'
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findCustomerServiceIssues(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }

    // exportData() {
    //   exportCardSilentManagesUnexpired(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
    //     if (res.code === 0 && res.data.is_direct_download === true) {
    //       exportExcel(res.data.id, res.data.file_name)
    //     }
    //   })
    // }
  }
}
</script>
