<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="标题">
            <a-input
              v-decorator="['title', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="状态">
            <a-select
              v-decorator="['status', { initialValue: '' }]"
              allow-clear
            >
              <a-select-option key="1" value="1">
                已解决
              </a-select-option>
              <a-select-option key="0" value="0">
                待处理
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="日期">
            <a-range-picker v-decorator="['happen_date']" />
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="客户">
            <a-select
              v-decorator="['agent_id']"
              :show-arrow="false"
              show-search
              allow-clear
              :filter-option="false"
              :not-found-content="loadingAgentOptions ? undefined : null"
              label-in-value
              placeholder="输入客户名称加载选项"
              @search="fetchAgentOptions"
              :loading="loadingAgentOptions"
            >
              <a-spin v-if="loadingAgentOptions" slot="notFoundContent" size="small" />
              <a-select-option
                v-for="option in agentOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="类型">
            <a-select
              :loading="loadingCustomerServiceIssueOptions"
              allow-clear
              :filter-option="filterOption"
              v-decorator="['issue_type']"
            >
              <a-select-option
                v-for="issue_type in customerServiceIssueOptions"
                :key="issue_type.value"
                :value="issue_type.value"
              >
                {{ issue_type.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
import { findAgentOptions } from '@/api/agent'
import { findCustomerServiceIssueOptions } from '@/api/common_options'

export default {
  name: 'SearchIssue',
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: 'issue_search' }),
      customerServiceIssueOptions: [],
      agentOptions: [],
      loadingCustomerServiceIssueOptions: false,
      loadingAgentOptions: false
    }
  },

  created() {
    this.fetchCustomerServiceIssueOption()
  },

  methods: {
    toggle() {
      this.expand = !this.expand
    },

    // 加载所属用户选项数据
    fetchAgentOptions(name) {
      if (!this.$lodash.trim(name)) {
        return
      }

      this.loadingAgentOptions = true
      findAgentOptions({ name: name }).then(res => {
        if (res.code === 0) {
          this.agentOptions = res.data
        }
        this.loadingAgentOptions = false
      })
    },

    fetchCustomerServiceIssueOption() {
      this.loadingCustomerServiceIssueOptions = true
      findCustomerServiceIssueOptions().then((res) => {
        if (res.code === 0) {
          this.customerServiceIssueOptions = res.data
        }
        this.loadingCustomerServiceIssueOptions = false
      })
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        agent_id: fieldsValue.agent_id && fieldsValue.agent_id.key,
        happen_date_begin: formatRangeBeginDate(fieldsValue.happen_date),
        happen_date_end: formatRangeEndDate(fieldsValue.happen_date)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
