import request from '@/utils/request'

// 查询设问题型列表数据
export function findIssueTypeOptions(params) {
  return request({
    url: `/customer_service/issue_types_options`,
    method: 'get',
    params: params
  })
}

// 获取问题记录列表
export function findCustomerServiceIssues(params) {
  return request({
    url: `/customer_service/issues`,
    method: 'get',
    params
  })
}

// 新增问题记录
export function createCustomerServiceIssues(data) {
  return request({
    url: `/customer_service/issues`,
    method: 'post',
    data
  })
}

// 更新问题记录
export function updateCustomerServiceIssues(id, data) {
  return request({
    url: `/customer_service/issues/${id}`,
    method: 'put',
    data
  })
}

// 批量删除问题记录
export function deleteCustomerServiceIssues(customerServiceIssueIds) {
  return request({
    url: `/customer_service/issues/batch`,
    method: 'delete',
    data: customerServiceIssueIds
  })
}

// 查询单个问题记录表单值
export function findCustomerServiceIssueForm(issueId) {
  return request({
    url: `/customer_service/issues/${issueId}/form`,
    method: 'get'
  })
}
